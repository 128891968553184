<template>
  <div>
    <div class="text-center">
      <img
        src="../../assets/img/history-reminder.png"
        alt=""
        style="width: 500px;height: 350px;"
        class="my-5"
      />
      <h1 class="text-center">Riwayat Minum Obat</h1>
    </div>
    <div v-for="(card, index) in cards" :key="index">
      <div class="p-3">
        <div class="row" :class="$style['konsumsi-section']">
          <div
            class="col-4 d-flex flex-column align-items-center justify-content-center"
            style="background: linear-gradient(to right, #41C8A4, #FFFFFF); border-radius: 20px 0 0 20px;"
            :style="{
              background: card.checked
                ? 'linear-gradient(to right, #41C8A4, #FFFFFF)'
                : 'linear-gradient(to right, #DD271A, #FFFFFF)',
            }"
          >
            <img
              src="../../assets/img/checklist-history.png"
              alt=""
              :class="$style['img-clock']"
              v-if="card.checked"
            />
            <img src="../../assets/img/x-history.png" alt="" :class="$style['img-clock']" v-else />

            <h1 class="text-white mt-3">{{ today }}</h1>
          </div>
          <div
            class="col-8 d-flex align-items-center justify-content-center"
            :class="$style['konsumsi-kanan']"
          >
            <div class="d-flex align-items-center justify-content-center">
              <img src="../../assets/img/obat-imfinzi.png" alt="" :class="$style['img-obat']" />
              <div class="ml-3">
                <p class="m-0" :class="$style['text-obat']">Tagrisso</p>
                <p class="m-0" :class="$style['text-obat']">
                  <strong>{{ card.jam ? card.jam : '-- : --' }}</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPatientProgram, getHistoryDrugsReminder } from '../../services/axios/pulih'
export default {
  data() {
    const visible = false
    const jenisTerapi = ''
    const drugsTakenDate = ''
    const medicines = ''
    const today = ''
    return {
      today,
      drugsTakenDate,
      medicines,
      jenisTerapi,
      visible,
      selectedFrekuensi: 1,
      maxFrekuensi: 10, // Misalkan maksimal frekuensi adalah 10
      cards: [{ jam: '' }],
    }
  },
  async mounted() {
    console.log('inidischedule')
    const userData = JSON.parse(localStorage.getItem('userData'))
    const jadwals = JSON.parse(localStorage.getItem('jadwals'))
    if (jadwals.length) {
      this.cards = jadwals
    }
    const res = await getPatientProgram(userData.user.code)
    this.jenisTerapi = res.programs[0].programType.name
    this.drugsTakenDate = res.programs[0].drugsTakenDate
    this.medicines = res.programs[0].price.count
    const response = await getHistoryDrugsReminder(res.id, this.drugsTakenDate, this.medicines)
    console.log(response, 'inihistoryobat')
    const currentDate = new Date()

    // Extract the year, month, and day
    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, '0') // Months are zero-based, so add 1
    const day = String(currentDate.getDate()).padStart(2, '0')

    // Format as YYYY-MM-DD
    const formattedDate = `${year}-${month}-${day}`
    this.today = formattedDate
    // console.log(this.drugsTakenDate, this.medicines)
    // console.log(res, 'inidata')
    // console.log(this.jenisTerapi)
  },
  methods: {
    toggleModal() {
      this.visible = !this.visible
    },
    handleFrekuensiChange() {
      const newFrekuensi = this.selectedFrekuensi
      const currentFrekuensi = this.cards.length

      // Jika frekuensi baru lebih besar, tambahkan card baru
      if (newFrekuensi > currentFrekuensi) {
        const tambahan = newFrekuensi - currentFrekuensi
        for (let i = 0; i < tambahan; i++) {
          this.cards.push({ jam: '' })
        }
      } else if (newFrekuensi < currentFrekuensi) {
        // Jika frekuensi baru lebih kecil, hapus card yang tidak perlu
        this.cards.splice(newFrekuensi)
      }
    },
    updateCardTime(index) {
      // Fungsi untuk memperbarui waktu pada card tertentu
      console.log(`Waktu pada card ${index + 1} diperbarui menjadi ${this.cards[index].jam}`)
    },
    handleOk() {
      console.log(this.cards, 'inisemuajawdals')
      this.cards = this.cards.map(e => {
        e.checked = false
        return e
      })
      const stringifiedCards = JSON.stringify(this.cards)
      // console.log('stringifiedcars', stringifiedCards)
      localStorage.setItem('jadwals', stringifiedCards)
      this.visible = false
    },
  },
}
</script>

<style scoped></style>

<style lang="scss" module>
@import '@/components/Auth/style.module.scss';
.card {
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}
.main-menu-item {
  width: 100%;
  height: auto;
  object-fit: cover;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.main-menu-item:hover {
  transform: scale(1.05);
  /* Slightly increase the size */
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 12px 40px 0 rgba(0, 0, 0, 0.24);
  /* Optionally enhance the shadow */
}

.title-section {
  font-size: 20px;
  color: #4f125f;
  width: 200px;
}

.custom-card {
  position: relative;
  overflow: hidden;
  /* Memastikan isi tidak melebihi batas card */
  border-radius: 17px;
}

.custom-card img {
  width: 100%;
  height: auto;
  /* Sesuaikan dengan kebutuhan, bisa juga height: 100% jika perlu */
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, #800152, transparent);
  width: 100%;
  height: 100%;
  /* Atur sesuai kebutuhan untuk efek gradient */
  z-index: 1;
}

.card-title {
  position: absolute;
  bottom: 10px;
  left: 30px;
  color: white;
  z-index: 2;
  /* Pastikan teks berada di atas overlay */
  width: 80%;
  /* Sesuaikan lebar teks */
}

/* Menargetkan elemen checkbox internal secara global */
:global(.ant-checkbox-inner) {
  width: 20px !important;
  /* Mengatur lebar */
  height: 20px !important;
  /* Mengatur tinggi */
  border-radius: 3px !important;
  /* Opsional: Menghapus border-radius */

  /* Center horizontal */
  border: 1px solid #707072 !important;
}

/* Opsi tambahan: Menyesuaikan ukuran tanda centang jika perlu */
:global(.ant-checkbox-inner::after) {
  /* Atur lebar tanda centang */
  // width: 20px !important;
  // height: 20px !important;
  /* Atur tinggi tanda centang */
  /* Pusatkan secara vertikal */
  // border-radius: 8px !important;
  display: flex !important;
  /* Menggunakan flexbox untuk align centang */
  align-items: center !important;
  /* Center vertical */
  justify-content: center !important;
  // top: 20px !important;
  left: 5px !important;
  // transform: scale(1) !important;
  // rotate: 40deg;
  /* Menyesuaikan posisi dan skala */
}

.custom-input-email-login {
  border: none !important;
  border-bottom: 1px solid #e4e9f0 !important;
  border-radius: 0 !important;

  &:focus {
    border-bottom: 1px solid #800152 !important;
  }

  &:hover {
    border-bottom: 1px solid #800152 !important;
  }
}

.custom-input-password-login {
  border: none !important;
  border-bottom: 1px solid #e4e9f0 !important;
  border-radius: 0 !important;

  &:focus-within {
    border-bottom: 1px solid #800152 !important;
  }

  &:hover {
    border-bottom: 1px solid #800152 !important;
  }
}

.custom-forget-pass {
  color: #800152 !important;
  border-bottom: 1px solid transparent;
  transition: 0.3s all ease-in-out;

  &:hover {
    border-bottom: 1px solid #800152 !important;
  }
}

.custom-register-link {
  color: #800152 !important;
  font-weight: 700;
  border-bottom: 1px solid transparent;
  transition: 0.3s all ease-in-out;

  &:hover {
    border-bottom: 1px solid #800152 !important;
  }
}

.custom-form-item {
  label::before {
    content: '' !important;
  }
}

.custom-button-yes {
  // width: 100%;
  height: 20px !important;
  font-weight: 500;
  font-size: 8px !important;
  text-transform: uppercase;
  background: linear-gradient(to right, #eda702, #c76b1e) !important;
  border: 1px solid #eda702 !important;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #eda702 !important;
    background: transparent !important;
  }
}

.custom-button-no {
  // width: 100%;
  height: 20px !important;
  font-weight: 500;
  font-size: 8px !important;
  text-transform: uppercase;
  background: linear-gradient(to right, #800251, #ba0672) !important;
  transition: all 0.3s ease-in-out;
  border: 1px solid #800251 !important;

  &:global(.ant-btn-primary:hover) {
    border: 1px solid #800251 !important;
  }

  &:hover {
    color: #800251 !important;
    background: transparent !important;
    border: 1px solid #800251 !important;
  }
}

.custom-container-login {
  width: 75% !important;
}

.custom-text-login {
  color: white;
}

@media (max-width: 960px) {
  .custom-container-login {
    width: 100% !important;
  }
}

.step-title {
  font-size: 18px;
}

.img-banner {
  width: 130px;
  height: 80px;
}

.banner-title {
  font-size: 10px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
}

.line {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30px;
  background-color: #4f4f4f;
  height: 1px;
}

.img-step {
  width: 32px;
  height: 32px;
}

.step p {
  font-size: 8px;
}

.img-clock {
  width: 38px;
  height: 35px;
}

.konsumsi-section {
  height: 70px;
}

.img-obat {
  width: 27px;
  height: 46px;
}

.text-obat {
  font-size: 12px;
}

.konsumsi-kanan {
  background-color: #ffffff;
  border-radius: 0 20px 20px 0;
  padding: 20px 30px 20px 30px;
}

.article-item {
  width: 280px;
  height: 130px;
  border-radius: 20px;
  margin-top: 10px;
}

.menu-title {
  font-size: 10px;
  height: auto;
}

//DEKSTOP
@media screen and (min-width: 1440px) {
  .main-menu-item {
    width: 100%;
    height: auto;
    object-fit: cover;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 30px;
  }

  .title-section {
    font-size: 32px;
    color: #4f125f;
    width: auto;
  }

  .img-banner {
    width: 327.75px;
    height: 205.68px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 50px;
  }

  .banner-title {
    font-size: 28px;
    width: 80%;
    margin-bottom: 50px;
  }

  .custom-button-yes {
    height: 60px !important;
    width: 80%;
    font-size: 24px !important;
  }

  .custom-button-no {
    height: 60px !important;
    width: 80%;
    font-size: 24px !important;
    margin-top: 5px;
  }

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80px;
  }

  .line {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    background-color: #4f4f4f;
    height: 1px;
  }

  .img-step {
    width: 64px;
    height: 64px;
  }

  .step p {
    font-size: 16px;
  }

  .title-step {
    height: 240px;
  }

  .step-title {
    font-size: 32px;
    width: 130px;
    text-align: center;
  }

  .menu-title {
    font-size: 18px;
  }

  .img-clock {
    width: 88px;
    height: 85px;
  }

  .konsumsi-section {
    height: 200px;
  }

  .img-obat {
    width: 65px;
    height: 100px;
  }

  .text-obat {
    font-size: 24px;
  }

  .konsumsi-kanan {
    background-color: #ffffff;
    border-radius: 0 20px 20px 0;
    padding: 50px 70px 50px 70px;
  }

  .article-item {
    width: 400px;
    height: 200px;
    border-radius: 20px;
    margin-top: 10px;
  }

  /* Menargetkan elemen checkbox internal secara global */
  :global(.ant-checkbox-inner) {
    width: 56px !important;
    /* Mengatur lebar */
    height: 56px !important;
    /* Mengatur tinggi */
    border-radius: 8px !important;
    /* Opsional: Menghapus border-radius */

    /* Center horizontal */
    border: 1px solid #707072 !important;
  }

  /* Opsi tambahan: Menyesuaikan ukuran tanda centang jika perlu */
  :global(.ant-checkbox-inner::after) {
    /* Atur lebar tanda centang */
    // width: 20px !important;
    // height: 20px !important;
    /* Atur tinggi tanda centang */
    /* Pusatkan secara vertikal */
    // border-radius: 8px !important;
    display: flex !important;
    /* Menggunakan flexbox untuk align centang */
    align-items: center !important;
    /* Center vertical */
    justify-content: center !important;
    top: 20px !important;
    left: 24px !important;
    transform: scale(3) !important;
    rotate: 40deg;
    /* Menyesuaikan posisi dan skala */
  }
}

//TABLET
@media screen and (max-width: 1439px) and (min-width: 768px) {
  .main-menu-item {
    width: 100%;
    height: auto;
    object-fit: cover;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 20px;
  }

  .title-section {
    font-size: 32px;
    color: #4f125f;
    width: auto;
  }

  .img-banner {
    width: 230px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .step-title {
    font-size: 32px;
    // width: 130px;
    text-align: center;
  }

  .banner-title {
    font-size: 16px;
    width: 80%;
  }

  .custom-button-yes {
    height: 30px !important;
    width: 80%;
    font-size: 12px !important;
  }

  .custom-button-no {
    height: 30px !important;
    width: 80%;
    font-size: 12px !important;
    margin-top: 5px;
  }

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70px;
  }

  .line {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70px;
    background-color: #4f4f4f;
    height: 1px;
  }

  .img-step {
    width: 50px;
    height: 50px;
  }

  .step p {
    font-size: 12px;
  }

  .title-step {
    height: 240px;
  }

  .img-clock {
    width: 48px;
    height: 45px;
  }

  .konsumsi-section {
    height: 100px;
  }

  .article-item {
    width: 280px;
    height: 130px;
    border-radius: 20px;
    margin-top: 10px;
  }
}

.doctor-prescription {
  /* max-width: 400px; */
  margin: auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: white;
  /* text-align: center; */
}

.doctor-info div {
  margin-bottom: 10px;
  text-align: left;
}

.upload-section {
  /* margin: 20px 0; */
}

.title {
  color: #333;
}

.label-col {
  text-align: right;
  padding-right: 15px;
}

.form-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.col-12 {
  flex: 1;
}

.col-sm-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-sm-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}
</style>
